import $ from 'jquery'
import component from './_component'

const responsiveBreakpoints = [
  {
    query: '--to-md',
    name: 'sp',
  },
  {
    query: '--md-lg',
    name: 'tb',
  },
  {
    query: '--lg',
    name: 'pc',
  },
]

function updateElementAttributes($element, state, attributes) {
  if (state === 'open') {
    $element.addClass('is-open')
    $element.removeClass('is-close')
    $element.attr(attributes)
  } else {
    $element.removeClass('is-open')
    $element.addClass('is-close')
    $element.attr(attributes)
  }
}

function closeAccordion($button, $content, animationType) {
  updateElementAttributes($button, 'close', { 'aria-expanded': false })

  if (animationType === 'slide') {
    $content.slideUp(300, function () {
      updateElementAttributes($content, 'close', { 'aria-hidden': true })
      $content.css({ display: '' })
    })
  } else {
    updateElementAttributes($content, 'close', { 'aria-hidden': true })
  }
}

function openAccordion($button, $content, animationType) {
  updateElementAttributes($button, 'open', { 'aria-expanded': true })

  if (animationType === 'slide') {
    $content.slideDown(300, function () {
      updateElementAttributes($content, 'open', { 'aria-hidden': false })
      $content.css({ display: '' })
    })
  } else {
    updateElementAttributes($content, 'open', { 'aria-hidden': false })
  }
}

function openAccordionFromUrlQuery() {
  const defaultOpenAccordions = new URLSearchParams(
    window.location.search
  ).getAll('accordion')

  if (!defaultOpenAccordions.length) {
    return
  }

  defaultOpenAccordions.forEach((openId) => {
    $(`.js-accordion#${openId}`).find('.js-accordion_btn').addClass('is-open')
  })
}

function addAccessibilityAttributes() {
  $('.js-accordion').each(function (i) {
    const $accordion = $(this)
    const $button = $accordion.find('.js-accordion_btn')
    const $content = $accordion.find('.js-accordion_contents')

    if ($content[0]) {
      const buttonId = 'a11y-accordion-btn' + i
      const contentId = 'a11y-accordion-content' + i

      $content.attr('id', contentId)
      $button.attr('aria-controls', contentId)
      $button.attr('id', buttonId)
    }
  })
}

function updateAccordionState(query, device) {
  if (!component.matchMedia[query].matches) {
    return
  }

  $('.js-accordion').each(function () {
    const $accordion = $(this)
    const $button = $accordion.find('.js-accordion_btn')
    const $content = $accordion.find('.js-accordion_contents')
    const animationType = $accordion.attr('data-animation-type')

    const responsive = $accordion.attr('data-accordion-responsive')
      ? JSON.parse($accordion.attr('data-accordion-responsive'))
      : { pc: false, tb: false, sp: false }

    if ($button.hasClass('is-open')) {
      openAccordion($button, $content, animationType)
    } else if ($button.hasClass('is-close') && responsive[device] !== null) {
      closeAccordion($button, $content, animationType)
    } else if (responsive[device] === null || responsive[device] === true) {
      openAccordion($button, $content, animationType)
    } else if (responsive[device] === false) {
      closeAccordion($button, $content, animationType)
    }

    if (responsive[device] === null) {
      $accordion.addClass('is-accordion-null')
      $button.attr('disabled', true)
    } else {
      $accordion.removeClass('is-accordion-null')
      $button.removeAttr('disabled')
    }
  })
}

function initializeAccordion() {
  $(document).on('click', '.js-accordion_btn', function (e) {
    e.preventDefault()

    const $button = $(this)
    const $accordion = $(this).closest('.js-accordion')
    const $content = $('#' + $button.attr('aria-controls'))
    const animationType = $accordion.attr('data-animation-type')

    if (!$content[0]) {
      return
    }

    if ($content.hasClass('is-open')) {
      closeAccordion($button, $content, animationType)
    } else {
      openAccordion($button, $content, animationType)
    }
  })
}

function setupAccordion() {
  openAccordionFromUrlQuery()
  addAccessibilityAttributes()

  responsiveBreakpoints.forEach((breakpoint) => {
    if (component.matchMedia[breakpoint.query].matches) {
      updateAccordionState(breakpoint.query, breakpoint.name)
    }

    component.matchMedia[breakpoint.query].addEventListener('change', (e) => {
      if (e.matches) updateAccordionState(breakpoint.query, breakpoint.name)
    })
  })
}

function ready() {
  setupAccordion()
}

export default {
  init() {
    initializeAccordion()
    $(function () {
      ready()
    })
  },
}
