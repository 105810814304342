// ヘッダー・グロナビ用のJSファイル

import $ from 'jquery'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import component from './_component.js'
import focusLoopStack from './_focusLoopStack.js'

/*
■ヘッダの処理

▼PC
・tabキーでのフォーカスで子メニューを開く
・tabキーでの子メニュー内の移動
・tabキーで子メニュー外に出た場合、子メニューを閉じる
・検索メニューなど、クリックでオープンするメニューもある

▼SP
・ハンバーガーボタンでGNAVをモーダル状態で開く
・子メニューはトグルで開く
・モーダルの上にさらにモーダル的に開くコンテンツがある可能性もあり
  その場合以下でセットアップ
  modalInit($target（モーダルコンテンツ）, $thisBtn（開いたボタン）, $humbergerBtn（なければ空）, closeFunction（閉じる処理）);

*/

// フォーカスで開くタイプの場合 true, クリックの場合 false
const isHoverType = true
// const isHoverType = false;

// gnavの切り替えタイミング
// PCSPで切り替えず、常にハンバーガーにする場合は '--all-match' にする
// --to-lgは0～1023px, --all-matchは0px～
const gnavMatchMedia = '--to-lg'
// const gnavMatchMedia = '--all-match';

const isHeaderFixed = false

let onGnav = false

// .js-header_toggle-targetのariaの状態リセット
const headerAriaSet = function () {
  const $this = $('.js-header_toggle-target')
  const id = $this.attr('id')
  const $controls = $('.js-header_toggle-open').filter(
    '[aria-controls="' + id + '"]'
  )

  if (component.matchMedia[gnavMatchMedia].matches) {
    // SP
    $this.attr({
      'aria-hidden': 'true',
    })
    $controls.attr({
      'aria-expanded': 'false',
    })
  } else {
    // PC
    $this.attr({
      'aria-hidden': 'false',
    })
    $controls.attr({
      'aria-expanded': 'true',
    })
  }
}

// .js-gnavのariaセット
const gnavAriaSet = function () {
  $('.js-gnav')
    .find('.js-gnav_item')
    .each(function (itemIndex) {
      $(this)
        .find('.js-gnav_link')
        .attr({
          role: 'button',
          'aria-expanded': 'false',
          'aria-controls': 'gnav_contents_' + itemIndex,
        })
      $(this)
        .find('.js-gnav_contents')
        .attr({
          id: 'gnav_contents_' + itemIndex,
          'aria-hidden': 'true',
        })
    })
}

const headerReset = function (isDirect) {
  // 全体をリセット
  headerToggleAllReset(isDirect)

  // 子メニューを閉じる
  gnavChildrenMenuClose($('.js-gnav'), {
    isDirect: isDirect,
    isSlide: !isDirect,
  })

  // フォーカスループコンテンツクリア
  focusLoopStack.delById('humbergerMenu')
}

const modalClose = function () {
  // 最上位のモーダル要素
  const modal = focusLoopStack.stack.pop()
  const $currentTarget = $(modal.targetEl)
  const $thisBtn = $(modal.openBtnEl)

  headerToggleClose($thisBtn, $currentTarget, function () {
    if ($thisBtn && $thisBtn[0]) {
      $thisBtn[0].focus()
    }
  })
}

const modalInit = function ($target, $thisBtn, $humbergerBtn, closeFunction) {
  // フォーカスループ開始
  // 開いたモーダル記録
  /*
  {
    id: string
    targetEl: HTMLElement
    openBtnEl: HTMLElement
    afterFocusEl: HTMLElement
    addFocus: {
      el: HTMLElement
      matchMedia: --to-md | --md | --lg | etc
    } <Array>
    closeFunction: function
  }
  */
  focusLoopStack.stack.push({
    id: 'humbergerMenu',
    type: 'globalMenu',
    targetEl: $target[0],
    openBtnEl: $thisBtn[0],
    afterFocusEl: null,
    addFocus: [{ el: $humbergerBtn[0] }],
    closeFunction: closeFunction,
  })

  // scroll lock
  disableBodyScroll($target[0])
}

// 閉じる処理 動作ボタン, モーダルコンテンツ, コールバック（modalCloseから利用）
const headerToggleClose = function ($thisBtn, $target, callback) {
  const $humbergerBtn = $('.js-header_toggle-open')
  $humbergerBtn.removeClass('is-active')
  $humbergerBtn.addClass('is-close-enter')
  $humbergerBtn.attr({
    'aria-expanded': 'false',
  })

  $('html').removeClass('is-gnav-open')
  $('html').removeClass('is-gnav-hover-open')

  $('.js-header_overlay').fadeOut(150, function () {
    $(this).css({ display: '' })
  })

  $target.slideUp(150, function () {
    $humbergerBtn.removeClass('is-close-enter')
    $target.css({ display: '' })
    $target.removeClass('is-active')
    $target.attr({
      'aria-hidden': 'true',
    })
  })

  if (callback && typeof callback === 'function') {
    callback()
  }

  enableBodyScroll($target[0])

  focusLoopStack.delById('humbergerMenu')
}

const headerToggleOpen = function ($thisBtn, $target) {
  const $humbergerBtn = $('.js-header_toggle-open')

  // overlayの表示状態により処理変更
  const $overlay = $('.js-header_overlay')
  const overlayHidden = $overlay.is(':hidden')

  // メニューリセット
  headerReset(true)

  // html
  $('html').addClass('is-gnav-open')

  // overlay
  // 元の表示状態によりフェードorダイレクト
  if (overlayHidden) {
    $overlay.show().css({ opacity: 0 })
    $overlay.animate({ opacity: 1 }, 150, function () {
      $target.css({ opacity: '' })
    })
  } else {
    $overlay.show()
  }

  // ヘッダーメニューコンテンツ表示
  /*
  $target.show().css({opacity: 0});
  $target.animate({opacity: 1}, 150, function() {
    $target.css({opacity: ''});
  });
  */
  $target.slideDown(150, function () {
    $target.addClass('is-active')
    $target.css({ display: '' })
  })

  // ボタン処理
  $humbergerBtn.addClass('is-active')
  $humbergerBtn.attr({ 'aria-expanded': 'true' })
  $target.attr({ 'aria-hidden': 'false' })

  // ヘッダをfixedにする場合、以下はコメントアウト
  if (!isHeaderFixed) {
    $(window).scrollTop(0)
  }

  // モーダルオープン処理
  modalInit($target, $thisBtn, $humbergerBtn, modalClose)
}

const headerToggleAllReset = function (isDirect) {
  $('html').removeClass('is-gnav-open')
  $('html').removeClass('is-gnav-hover-open')

  if (isDirect) {
    $('.js-header_overlay').hide()
    $('.js-header_toggle-open').removeClass('is-active')
    $('.js-header_toggle-target').hide().css({ display: '' })
  } else {
    $('.js-header_toggle-target').slideUp(150, function () {
      $('.js-header_toggle-open').removeClass('is-active')
      $('.js-header_toggle-target').css({ display: '' })
    })

    $('.js-header_overlay').fadeOut(150, function () {
      $(this).css({ display: '' })
    })
  }

  headerAriaSet()
  clearAllBodyScrollLocks()
}

const gnavChildrenMenuStateChange = function ($el, isOpen) {
  const $gnavLink = $el.find('.js-gnav_link')
  const $gnavContents = $el.find('.js-gnav_contents')
  if (isOpen) {
    $gnavLink.attr({ 'aria-expanded': 'true' })
    $gnavContents.attr({ 'aria-hidden': 'false' })
    $gnavLink.addClass('is-active')
    $el.addClass('is-active')
  } else {
    $gnavLink.attr({ 'aria-expanded': 'false' })
    $gnavContents.attr({ 'aria-hidden': 'true' })
    $gnavLink.removeClass('is-active')
    $el.removeClass('is-active')
  }
}

const gnavChildrenMenuClose = function ($box, arg) {
  /*
  arg = {
    isDirect: boolean,
    isFade: boolean,
    isSlide: boolean,
    callback: function,
    focusAfterClosed: HTMLElement,
    isOpenGnav: boolean,
  }
  */
  const $gnavItem = $box.hasClass('js-gnav_item')
    ? $box
    : $box.find('.js-gnav_item')

  // state変更(ariaなど)
  gnavChildrenMenuStateChange($gnavItem, false)

  // 表示切り替え
  if (arg.isFade) {
    $gnavItem.stop(true, true).find('.js-gnav_contents').fadeOut(150)
  } else if (arg.isDirect) {
    $gnavItem.find('.js-gnav_contents').hide()
    if (!onGnav) {
      $('.js-header_overlay').hide()
      $('html').removeClass('is-gnav-hover-open')
    }
  } else {
    $gnavItem.find('.js-gnav_contents').slideUp(150, function () {})

    if (!component.matchMedia[gnavMatchMedia].matches && !arg.isOpenGnav) {
      // PC & 他のGnavが開いている場合はoverlayを消さない
      $('.js-header_overlay').fadeOut(150, function () {})
      $('html').removeClass('is-gnav-hover-open')
    }
  }

  if (arg.focusAfterClosed) {
    // focusinが発火してしまうため一旦フォーカス移動はしない
    // arg.focusAfterClosed.focus({preventScroll: true});
  }
}

let gnavChildrenMenuOpenTimer = 0
const gnavChildrenMenuOpen = function ($box, arg) {
  /*
  arg = {
    isDirect: boolean,
    isFade: boolean,
    isSlide: boolean,
    isOpenGnav: boolean,
    isOhterClose: boolean,
  }
  */
  const $gnavItem = $box.hasClass('js-gnav_item')
    ? $box
    : $box.find('.js-gnav_item')
  let waitTime = 0

  clearTimeout(gnavChildrenMenuOpenTimer)

  if (arg.isOhterClose) {
    // SP時はトグルになるため、自分以外も開いたまま
    // PC時は自分以外は閉じる
    waitTime = arg.isOpenGnav ? 100 : 0
    gnavChildrenMenuClose($('.js-gnav'), arg)
  }

  // 表示切り替え
  if (arg.isFade) {
    gnavChildrenMenuOpenTimer = setTimeout(function () {
      $gnavItem.stop(true, true).find('.js-gnav_contents').fadeIn(150)
      $('.js-header_overlay')
        .stop()
        .fadeIn(150, function () {})
      $('html').addClass('is-gnav-hover-open')
    }, waitTime)
  } else if (arg.isDirect) {
    $gnavItem.find('.js-gnav_contents').show()
    $('.js-header_overlay')
      .stop()
      .fadeIn(0, function () {})
    $('html').addClass('is-gnav-hover-open')
  } else if (arg.isSlide) {
    gnavChildrenMenuOpenTimer = setTimeout(function () {
      $gnavItem.find('.js-gnav_contents').slideDown(150, function () {})
      $('.js-header_overlay')
        .stop()
        .fadeIn(150, function () {})
      $('html').addClass('is-gnav-hover-open')
    }, waitTime)
  }

  // state変更(ariaなど)
  gnavChildrenMenuStateChange($gnavItem, true)
}

// Escキーで閉じる
const escapeClose = function (focusAfterClosed) {
  $(document).off('keydown.gnavChildrenEscapeClose')
  $(document).on('keydown.gnavChildrenEscapeClose', function (e) {
    if (e.key === 'Escape') {
      e.preventDefault()
      gnavChildrenMenuClose($('.js-gnav'), {
        isSlide: true,
        focusAfterClosed: focusAfterClosed,
      })
    }
  })
}

// PC Hover処理
const onGnavLinkHoverOpen = function (e) {
  const $this = $(this)
  const $parentGnav = $this.closest('.js-gnav')
  clearTimeout($this.data('mouseOutTimer'))

  if ($this.find('.js-gnav_link').filter('[aria-expanded="true"]')[0]) {
    // 開いている場合stop
    return
  }

  const isOpenGnav = !!$parentGnav
    .find('.js-gnav_item')
    .not($this)
    .find('.js-gnav_link')
    .filter('[aria-expanded="true"]')[0]

  setTimeout(function () {
    // onGnavの切り替わりを待つためsetTimeout 0
    const waitTime =
      $(e.relatedTarget).hasClass('js-gnav') ||
      $(e.relatedTarget).closest('.js-gnav')[0]
        ? 150
        : 250
    const timer = setTimeout(function () {
      const isOpen = !!$parentGnav
        .find('.js-gnav_link')
        .filter('[aria-expanded="true"]')[0]

      if (isOpen) {
        gnavChildrenMenuOpen($this, {
          isFade: true,
          isOhterClose: true,
          isOpenGnav: isOpenGnav,
        })
      } else {
        gnavChildrenMenuOpen($this, {
          isSlide: true,
          isOhterClose: true,
          isOpenGnav: isOpenGnav,
        })
      }
    }, waitTime)

    $this.data('mouseInTimer', timer)

    escapeClose($this.find('.js-gnav_link')[0])
  }, 0)
}

const onGnavLinkHoverClose = function () {
  const $this = $(this)
  const $parentGnav = $this.closest('.js-gnav')

  clearTimeout($(this).data('mouseInTimer'))

  const timer = setTimeout(function () {
    // 開いた状態なら
    if ($this.find('.js-gnav_link').filter('[aria-expanded="true"]')[0]) {
      // 他のgnavが開いていれば
      if (
        $parentGnav
          .find('.js-gnav_item')
          .not($this)
          .find('.js-gnav_link')
          .filter('[aria-expanded="true"]')[0]
      ) {
        gnavChildrenMenuClose($this, {
          isFade: true,
        })
      } else {
        gnavChildrenMenuClose($this, {
          isSlide: true,
        })
      }
    }
  }, 400)

  $(this).data('mouseOutTimer', timer)
}

// PC Click処理
const onGnavLinkOutsideClick = function (e) {
  if (!e.target.closest('.js-gnav_container')) {
    // 外側
    gnavChildrenMenuClose($('.js-gnav'), {
      isSlide: true,
    })
  }
}

const onGnavLinkClickOpen = function () {
  const $this = $(this)
  const $parentGnav = $this.closest('.js-gnav')
  const isOpenGnav = !!$parentGnav
    .find('.js-gnav_item')
    .not($this)
    .find('.js-gnav_link')
    .filter('[aria-expanded="true"]')

  gnavChildrenMenuOpen($this, {
    isSlide: true,
    isOhterClose: true,
    isOpenGnav: isOpenGnav,
  })

  $(document).on('click.gnavLinkOutsideClick', onGnavLinkOutsideClick)
  escapeClose($this.find('.js-gnav_link')[0])
}

const onGnavLinkClickClose = function () {
  const $this = $(this)

  gnavChildrenMenuClose($this, {
    isSlide: true,
  })

  $(document).off('click.gnavLinkOutsideClick')
}

function init() {
  // SP版、PC版への切り替え時リセット
  component.matchMedia[gnavMatchMedia].addListener(function () {
    headerReset(true)
  })

  // GNAV  SP時用トグル処理
  $(document).on('click', '.js-gnav_link', function (e) {
    if (component.matchMedia[gnavMatchMedia].matches) {
      // SP
      e.preventDefault()

      const $box = $(this).closest('.js-gnav_item')
      if ($box.hasClass('is-active')) {
        gnavChildrenMenuClose($box, {
          isSlide: true,
        })
      } else {
        gnavChildrenMenuOpen($box, {
          isSlide: true,
          isOhterClose: false,
        })
      }
    }
  })

  // GNAVメニュー  ホバータイプ用
  $(document).on('mouseenter focusin', '.js-gnav', function () {
    onGnav = true
  })
  $(document).on('mouseleave focusout', '.js-gnav', function () {
    onGnav = false
  })

  if (isHoverType) {
    // GNAVメニュー  ホバータイプの場合
    $(document).on('mouseenter focusin', '.js-gnav_item', function (e) {
      if (!component.matchMedia[gnavMatchMedia].matches) {
        // PC
        onGnavLinkHoverOpen.call(this, e)
      }
    })
    $(document).on('mouseleave focusout', '.js-gnav_item', function (e) {
      if (!component.matchMedia[gnavMatchMedia].matches) {
        // PC
        onGnavLinkHoverClose.call(this, e)
      }
    })
  } else {
    // GNAVメニュー  クリックタイプの場合
    $(document).on('click', '.js-gnav_link', function (e) {
      if (!component.matchMedia[gnavMatchMedia].matches) {
        // PC
        e.preventDefault()
        const $parent = $(this).closest('.js-gnav_item')
        if ($parent.hasClass('is-active')) {
          onGnavLinkClickClose.call($parent[0], e)
        } else {
          onGnavLinkClickOpen.call($parent[0], e)
        }
      }
    })
  }

  // GNAVメニュー内に閉じるボタンを入れる場合
  $(document).on('click', '.js-gnav_close', function () {
    gnavChildrenMenuClose($('.js-gnav'), {
      isSlide: true,
    })
  })

  // ハンバーガーボタン
  $(document).on('click', '.js-header_toggle-open', function (e) {
    e.preventDefault()
    const $this = $(this)
    const $target = $('#' + $this.attr('aria-controls'))

    if ($this.hasClass('is-active')) {
      headerToggleClose($this, $target)
    } else {
      headerToggleOpen($this, $target)
    }
  })

  // ハンバーガー内にCLOSEボタンを入れる場合
  $(document).on('click', '.js-header_toggle-close', function () {
    const id = $(this).closest('.js-header_toggle-target').attr('id')
    $('.js-header_toggle-open[aria-controls="' + id + '"]')
      .eq(0)
      .trigger('click')
  })

  // GNAV内からのアンカーリンクでOPEN状態をリセット
  $(document).on(
    'click',
    '.js-header_toggle-target .js-anchor[href^="#"]',
    function () {
      headerReset()
    }
  )
}

function ready() {
  // セットアップ
  headerAriaSet()
  gnavAriaSet()
}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
