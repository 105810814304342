// ページトップへ戻るボタン

import $ from 'jquery'

function init() {
  //スクロールした際の動きを関数でまとめる
  function PageTopAnime() {
    let scroll = $(window).scrollTop() //スクロール値を取得
    if (scroll >= 250) {
      //200pxスクロールしたら
      $('#p-footer-page-top').removeClass('js-down-move') // js-down-moveというクラス名を除去して
      $('#p-footer-page-top').addClass('js-up-move') // js-up-moveというクラス名を追加して出現
    } else {
      //それ以外は
      if ($('#p-footer-page-top').hasClass('js-up-move')) {
        //js-up-moveというクラス名が既に付与されていたら
        $('#p-footer-page-top').removeClass('js-up-move') //  js-up-moveというクラス名を除去し
        $('#p-footer-page-top').addClass('js-down-move') // js-down-moveというクラス名を追加して非表示
      }
    }

    let wH = window.innerHeight //画面の高さを取得
    let footerPos = $('#footer').offset().top //footerの位置を取得
    if (scroll + wH >= footerPos + 10) {
      let pos = scroll + wH - footerPos + 10 //スクロールの値＋画面の高さからfooterの位置＋10pxを引いた場所を取得し
      $('#p-footer-page-top').css('bottom', pos) //#page-topに上記の値をCSSのbottomに直接指定してフッター手前で止まるようにする
    } else {
      //それ以外は
      if ($('#p-footer-page-top').hasClass('js-up-move')) {
        //js-up-moveというクラス名がついていたら
        $('#p-footer-page-top').css('bottom', '10px') // 下から10pxの位置にページリンクを指定
      }
    }
  }

  // 画面をスクロールをしたら動かしたい場合の記述
  $(window).scroll(function () {
    PageTopAnime() /* スクロールした際の動きの関数を呼ぶ*/
  })

  // // ページが読み込まれたらすぐに動かしたい場合の記述
  // $(window).on('load', function () {
  // 	PageTopAnime();/* スクロールした際の動きの関数を呼ぶ*/
  // });

  // デバイス判定関数
  function isMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  }

  // #p-footer-page-topをクリックした際の設定
  $('#p-footer-page-top').on('click', function (event) {
    event.preventDefault() // リンク自体の無効化
    let scrollDuration = isMobile() ? 300 : 0
    $('html, body').animate({ scrollTop: 0 }, scrollDuration, 'linear') // ページトップまでスクロール
    return false // リンク自体の無効化
  })
}

export default {
  init() {
    init()
  },
}
