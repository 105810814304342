import getUniqueStr from './_getUniqueStr'

function setUniqueStrId(idName) {
  idName = typeof idName === 'undefined' ? '' : idName
  return idName + '_' + getUniqueStr()
}

export default function (idName) {
  let uniqueStrId = setUniqueStrId(idName)
  while (document.getElementById(uniqueStrId)) {
    uniqueStrId = setUniqueStrId(idName)
  }
  return uniqueStrId
}
