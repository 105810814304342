// 現ページのメニューの色を変える

function init() {
  const links = document.querySelectorAll('.p-header-nav__link')
  const currentLocation = new URL(location.href)

  links.forEach(function (link) {
    const linkUrl = new URL(link.href)

    // トップページの場合
    if (currentLocation.pathname === '/') {
      if (linkUrl.pathname === '/') {
        link.classList.add('_current')
      }
    } else {
      // トップページ以外の場合
      if (linkUrl.pathname !== '/') {
        if (currentLocation.href.includes(linkUrl.href)) {
          link.classList.add('_current')
        }
      }
    }
  })
}

export default {
  init() {
    init()
  },
}
