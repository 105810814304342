import $ from 'jquery'

// 375px未満はviewportを固定する
function init() {
  const viewport = document.querySelector('meta[name="viewport"]')
  function switchViewport() {
    const value =
      window.outerWidth > 375
        ? 'width=device-width,initial-scale=1'
        : 'width=375'
    if (viewport.getAttribute('content') !== value) {
      viewport.setAttribute('content', value)
    }
  }
  addEventListener('resize', switchViewport, false)
  switchViewport()
}
function ready() {}

export default {
  init() {
    init()
    $(function () {
      ready()
    })
  },
}
