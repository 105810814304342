// _pluginsフォルダからfocusable.jsをインポート
import focusable from './_plugins/focusable'
// focusable関数を呼び出す
focusable()

// jQueryをインポート
import $ from 'jquery'

// 各モジュールをインポート
import component from './_import/_component'
import matchMedia from './_import/_matchMedia'
import lockViewportOnNarrowScreens from './_import/_lockViewportOnNarrowScreens'
import handleVhCssVar from './_import/_handleVhCssVar'
import handleScrollbarCssVar from './_import/_handleScrollbarCssVar'
import tab from './_import/_tab'
import accordion from './_import/_accordion'
import modal from './_import/_modal'
import globalNav from './_import/_globalNav'
import addClassOnViewportEntry from './_import/_addClassOnViewportEntry'
import currentMenu from './_import/_current-menu'
import pageTop from './_import/_page-top'

// componentオブジェクトに'matchMedia'という名前でmatchMediaモジュールを追加
component.add('matchMedia', matchMedia)

// 各モジュールの初期化関数を実行
lockViewportOnNarrowScreens.init()
handleVhCssVar.init()
handleScrollbarCssVar.init()
globalNav.init()
tab.init()
accordion.init()
modal.init()
addClassOnViewportEntry.init()
currentMenu.init()
pageTop.init()

// componentオブジェクトに各モジュールを追加
component.add('tab', tab)
component.add('modal', modal)
component.add('accordion', accordion)

// 初期化関数を定義し、'no-js'クラスを削除する
const init = () => {
  $('html').removeClass('no-js')
}

// 初期化関数を実行
init()
